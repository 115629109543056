<template>
  <b-row class="px-1">
    <b-col
      v-for="{ link, name, iconSrc } in routes"
      :key="link"
      cols="12"
      sm="6"
      md="4"
      lg="3"
      style="padding-left: 0.5rem; padding-right: 0.5rem"
    >
      <LinkCard :to="link" :name="name" :icon="iconSrc" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import LinkCard from "./components/LinkCard.vue";
export default {
  components: {
    BRow,
    BCol,
    LinkCard,
  },
  data: function () {
    return {
      routes: [
        { link: "treatments", name: "treatments", iconSrc: "" },
        { link: "treatmentTypes", name: "treatmentTypes", iconSrc: "" },
        { link: "spermas", name: "spermas", iconSrc: "" },
        { link: "exams", name: "exams", iconSrc: "" },
        { link: "medicines", name: "medicinesAndVaccines", iconSrc: "" },
        { link: "illnesses", name: "illnesses", iconSrc: "" },
        { link: "illnessesTypes", name: "illnessesTypes", iconSrc: "" },
        { link: "barns", name: "barns", iconSrc: "" },
        { link: "parameters", name: "parameters", iconSrc: "" },
        { link: "synchronizations", name: "synchronizations", iconSrc: "" },
        { link: "animals-group", name: "animalGroups", iconSrc: "" },
        { link: "machines", name: "machines", iconSrc: "" },
      ],
    };
  },
};
</script>

<style></style>

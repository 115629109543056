<template>
  <!-- Router card -->
  <b-link :disabled="disabled" :to="{ name: to }">
    <b-card class="link-card">
      <div class="d-flex justify-content-between p-relative">
        <span
          :style="'opacity:' + opacity + '%;'"
          class="bg-secondary p-absolute text-white"
        >
          Buraya iconlar gelecek
        </span>
        <b-img v-if="icon" :src="icon" />
        <feather-icon v-else size="25" icon="CircleIcon" />
        <p class="text-dark ml-2 font-weight-bold h4">
          {{ $t(name) }}
        </p>
      </div>
      <span class="align-middle ml-50 text-white">{{ $t("view") }}</span>
    </b-card>
  </b-link>
</template>

<script>
import { BLink, BCard } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BCard,
  },
  props: {
    to: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      opacity: 100,
    };
  },
  mounted() {
    var interval = setInterval(() => {
      if (this.opacity > 0) this.opacity = this.opacity - 0.5;
      else clearInterval(interval);
    }, 10);
  },
};
</script>

<style></style>
<style>
.link-card {
  min-height: 120px;
}
.link-card > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: -4px 3px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
}
.link-card > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.link-card:hover {
  transform: translate(-3px, -4px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.link-card > div > span {
  font-size: 1.3rem;
  font-weight: bold;
  background-color: #fe671d;
  padding: 6px 10px;
  border-radius: 4px;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  width: 100%;
  text-align: center;
  box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.3);
}
.link-card:hover > div > span {
  font-size: 1.4rem;
  font-weight: bold;
  background-color: #fe671d;
  transform: scale(1.05);
  border-radius: 4px;
}
.p-absolute {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
